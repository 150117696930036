import axios from "axios";

export default class BackendConnectionService {

    static getEndpoint() {
        return process.env.REACT_APP_API_ENDPOINT;
    }

    static buildHeaders(token) {
        return {
            "G-TOKEN": token
        };
    }

    static buildAuthHeader(token) {
        return {
            Authorization: "Bearer " + token
        }
    }

    static loginAttempt(password,captcha) {
        let endpoint = this.getEndpoint() + "v2/login";
        return axios.post(endpoint, {
            password: password,
            token: captcha
        })
    }

    static changePassword(token, current, newPass) {
        let endpoint = this.getEndpoint() + "v2/configuration/password";
        let headers = this.buildAuthHeader(token);
        return axios.post(endpoint, {
            currentPassword: current,
            newPassword: newPass
        }, {
            headers: headers
        });
    }

    static addPasaporte(isDNI, token, etiqueta) {
        let endpoint = this.getEndpoint() + "v2/passport";
        let headers = this.buildAuthHeader(token);
        if (isDNI) {
            return axios.post(endpoint,
            {
                dni: etiqueta
            },
            {
                headers: headers
            });
        }
        return axios.post(endpoint,
            {
                tagId: etiqueta
            },
            {
                headers: headers
            });
    }

    static markPasaporte(token, etiqueta) {
        let endpoint = this.getEndpoint() + "v2/passport/" + etiqueta;
        let headers = this.buildAuthHeader(token);
        return axios.put(endpoint,{}, {
            headers: headers
        });
    }

    static markDNI(token, dni) {
        let endpoint = this.getEndpoint() + "v2/dni/" + dni;
        let headers = this.buildAuthHeader(token);
        return axios.put(endpoint,{}, {
            headers: headers
        });
    }

    static deletePasaporte(token, etiqueta) {
        let endpoint = this.getEndpoint() + "v2/passport/" + etiqueta;
        let headers = this.buildAuthHeader(token);
        return axios.delete(endpoint, {
            headers: headers
        });
    }

    static getPasaporte(token, etiqueta) {
        let endpoint = this.getEndpoint() + "v2/passport/" + etiqueta;
        let headers = this.buildHeaders(token);
        return axios.get(endpoint, {
            headers:headers
        });
    }

    static getDNI(token, dni) {
        let endpoint = this.getEndpoint() + "v2/dni/" + dni;
        let headers = this.buildHeaders(token);
        return axios.get(endpoint, {
            headers:headers
        });
    }

    static uploadDNIs(token, data) {
        let endpoint = this.getEndpoint() + "v2/dni";
        let headers = this.buildAuthHeader(token);
        return axios.post(endpoint,data, {
            headers: headers
        });
    }

    static uploadPasaportesBatch(token, data) {
        let endpoint = this.getEndpoint() + "v2/passport/batch";
        let headers = this.buildAuthHeader(token);
        return axios.post(endpoint,data, {
            headers: headers
        });
    }

    static getTramiteMetadata(token,tramite) {
        let endpoint = this.getEndpoint() + "v2/"+tramite+"/metadata";
        let headers = this.buildAuthHeader(token);
        return axios.get(endpoint, {
            headers: headers
        });
    }

    static getInstanceConfiguration() {
        let endpoint = this.getEndpoint() + "v2/configuration";
        return axios.get(endpoint);
    }

    static getTramiteConfiguration(tramite) {
        let endpoint = this.getEndpoint() + "v2/"+ tramite + "/configuration";
        return axios.get(endpoint);
    }

    static updateTramiteConfiguration(token, tramite, data) {
        let endpoint = this.getEndpoint() + "v2/"+ tramite + "/configuration";
        let headers = this.buildAuthHeader(token);
        return axios.post(endpoint, data, {
            headers: headers
        });
    }

}