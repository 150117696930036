import {withTranslation} from "react-i18next";
import React from "react";
import Navbar from "../../components/navbar";
import AdminMenu from "../../components/admin/adminMenu";
import Footer from "../../components/footer";
import PasaporteForm from "../../components/admin/tramites/PasaporteForm";
import LocalStorageService from "../../ports/localStorage/localStorageService";
import BackendConnectionService from "../../ports/backend/backendConnectionService";
import ResponseProcessorService from "../../ports/backend/ResponseProcessorService";
import Toastify from "../../components/alerts/Toastify";
import {toast} from "react-toastify";
import SubidaResponse from "../../components/admin/subidaResponse";
import SubirPasaporte from "../../components/admin/tramites/SubirPasaporte";

class AdminPass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estado: null,
            response: null,
            actualizacion: null
        }
    }

    componentDidMount() {
        let token = LocalStorageService.getUserToken();
        BackendConnectionService.getTramiteMetadata(token, "passport")
            .then((response) => {
                let info = ResponseProcessorService.processTramiteMetadata(response);
                this.setState(info);
            }).catch((err) => {
            this.processError(err);
        })
    }

    processError(error) {
        let payload = ResponseProcessorService.processResponse(error);
        if(payload.errorCode === 401) {
            LocalStorageService.logout();
            window.location.assign("/admin/inactive");
        } else {
            Toastify.error(toast, payload.errorMsg);
        }
    }

    render() {
        let  {response, actualizacion, estado} = this.state;
        if(estado == null) estado = "NODATA";

        let formulario = <SubirPasaporte />;
        if(estado === "PROCESSING") {
            formulario = (<div className="alert alert-primary" role="alert">
                Se está procesando un fichero actualmente. El formulario volverá a estar disponible cuando termine el proceso.
            </div>);
        }

        return(
            <div id="layout-wrapper">
                <Navbar />
                <AdminMenu />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Habilitar varias etiquetas</h4>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    {formulario}
                                                </div>
                                                <div className="col-lg-6">
                                                    <h4 className="header-title mb-4">Estado del último archivo subido</h4>
                                                    <SubidaResponse response={response} actualizacion={actualizacion} clase={estado} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Habilitar etiquetas</h4>
                                           
                                            <PasaporteForm action={"add"} text={"Habilitar"} />
                                      
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Marcar documento como recogido</h4>
                                            <PasaporteForm action={"mark"} text={"Recoger"} />              
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Eliminar etiqueta erronea</h4>
                                        
                                             <PasaporteForm action={"delete"} text={"Eliminar"} />
                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        );
    }
}

export default withTranslation()(AdminPass);