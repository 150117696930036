import React from "react";
import BackendConnectionService from "../../ports/backend/backendConnectionService";
import DOMPurify from "dompurify";

export default class TramiteResponse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            readyMessage : "",
            errorMessage : "",
            pickedMessage : "",
            messagesLoaded : false
        }
    }
    componentDidMount() {
        BackendConnectionService.getTramiteConfiguration(this.props.tramite)
        .then((e) => {
            this.setState({
                readyMessage: e.data.readyMessage,
                errorMessage: e.data.errorMessage,
                pickedMessage: e.data.pickedMessage,
                messagesLoaded: true
            });
        })
        .catch((e) => {
            console.log("TramiteResponse: Error cargando metadatos");
        });
    }

    makeQueryMessage() {
        return(
            <div className="row">
                <div className="col-md-12">
                    <div className="mt-4">
                        <div className="alert alert-primary" role="alert">
                            <div className="spinner-border text-primary mr-2 mt-2" role="status">
                                <span className="sr-only">Cargando...</span>
                            </div>
                            Realizando consulta...
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    makeErrorMessage() {
        return(
            <div className="row">
                <div className="col-md-12">
                    <div className="mt-4">
                        <div className="alert alert-warning" role="alert">
                            Hubo un error procesando la solicitud de información. Vuelva a intentarlo más tarde.
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    makeNotReadyMessage(message, numero) {
        message = message.replace("{numero}", numero);
        const sanitizedMessage = DOMPurify.sanitize(message);
        return this.makeResponseStructure(
            <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
        );
    }

    makePickedUpMessage(message, numero, fecha) {
        message = message.replace("{numero}", numero);
        message = message.replace("{fecha}", fecha);
        const sanitizedMessage = DOMPurify.sanitize(message);
        return this.makeResponseStructure(
            <div className="alert alert-info" role="alert" dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
        );
    }

    makeReadyMessage(message, numero) {
        message = message.replace("{numero}", numero);
        const sanitizedMessage = DOMPurify.sanitize(message);
        return this.makeResponseStructure(
            <div className="alert alert-success" role="alert" dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
        );
    }

    makeResponseStructure(response) {
        return(
            <div className="row">
                <div className="col-md-12">
                    <div className="mt-4">
                        {response}
                    </div>
                </div>
            </div>
        );
    }


    render() {
        let response = this.props.response;
        const {readyMessage, errorMessage, pickedMessage} = this.state;
    
        if(response == null) {
            return(<div />);
        } else {
             if(response.status === "query") {
                 return this.makeQueryMessage();
             } else if (response.status === "response") {
                let data = response.data;
                 if(!data.error) {
                     if(data.ready) {
                         if(data.recogido === null) {
                             return this.makeReadyMessage(readyMessage, data.documento);
                         } else {
                            return this.makePickedUpMessage(pickedMessage, data.documento, data.recogido);
                         }
                     } else {
                        return this.makeNotReadyMessage(errorMessage, data.documento); 
                     }
                 } else {
                     return this.makeErrorMessage();
                 }
             }
        }
    }
}