import React from "react";
import {withTranslation} from "react-i18next";

class SubidaResponse extends React.Component {
    render() {
        let response = this.props.response;
        let actualizacion = this.props.actualizacion;
        let clase = this.props.clase;
        if(clase === "OK") {
            return (
                <div className="alert alert-success" role="alert">
                    <p>El último fichero se subió correctamente el día {actualizacion} con la siguiente información:</p>
                    <div dangerouslySetInnerHTML={{__html: response}} />
                </div>
            );
        } else if (clase === "ERROR") {
            return(<div className="alert alert-danger mb-0" role="alert">
                <p>Hubo un error con la subida del último fichero. Revise la respuesta y vuelva a intentarlo.</p>
                <p>Última actualización: {actualizacion}</p>
                <p>Respuesta: </p>
                <div dangerouslySetInnerHTML={{__html: response}} />
            </div>);
        } else if(clase === "PROCESSING") {
            return(<div className="alert alert-primary" role="alert">
                <div className="spinner-border text-primary mr-2 mt-2" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <p>Se está procesando un fichero actualmente. El formulario estará deshabilitado hasta que haya terminado el proceso.</p>
                <p>Última actualización: {actualizacion}</p>
                <div dangerouslySetInnerHTML={{__html: response}} />
            </div>);
        } else if(clase === "NODATA") {
            return(<div className="alert alert-primary" role="alert">
                <p>No se han cargado datos todavía</p>
            </div>);
        } else {
            return(<div className="alert alert-primary" role="alert">
                <p>Cargando respuesta...</p>
            </div>);
        }
    }
}
export default withTranslation()(SubidaResponse);