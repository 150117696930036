

export default class LocalStorageService {
    static logout() {
        localStorage.removeItem("user_token");
    }
    static getUserToken() {
        return localStorage.getItem("user_token");
    }
    static getUserName() {
        return localStorage.getItem("user_name");
    }
    static isFirstSetup() {
        return localStorage.getItem("first_setup") == null
    }
    static getUserLanguage() {
        let lang = localStorage.getItem("i18nextLng");
        if(lang == null) {
            lang = "es-ES";
        }
        return lang;
    }
    static setUserInformation(data) {
        localStorage.setItem("user_token", data.token);
        localStorage.setItem("user_name", "Administrador");
    }
    static doFirstSetup() {
        localStorage.setItem("first_setup", "true");
    }
    static changeTramite(tramite) {
        localStorage.setItem("tramite",tramite);
    }
    static getTramite() {
        return localStorage.getItem("tramite");
    }
    static setInstanceConfiguration(config) {
        localStorage.setItem("instance_name", config.name);
        localStorage.setItem("instance_displayMethod", config.displayMethod);
        localStorage.setItem("instance_headerLogo", config.headerLogo);
        localStorage.setItem("instance_adminLogo", config.adminLogo);
        localStorage.setItem("instance_adminEmail", config.adminEmail);
    }
    static getInstanceConfiguration() {
        return {
            name: localStorage.getItem("instance_name"),
            displayMethod: localStorage.getItem("instance_displayMethod"),
            headerLogo: localStorage.getItem("instance_headerLogo"),
            adminLogo: localStorage.getItem("instance_adminLogo"),
            adminEmail: localStorage.getItem("instance_adminEmail")
        }
    }
}