import React from "react";
import Navbar from "../../components/navbar";
import AdminMenu from "../../components/admin/adminMenu";
import Footer from "../../components/footer";
import BackendConnectionService from "../../ports/backend/backendConnectionService";
import LocalStorageService from "../../ports/localStorage/localStorageService";
import Toastify from "../../components/alerts/Toastify";
import {toast} from "react-toastify";
import ResponseProcessorService from "../../ports/backend/ResponseProcessorService";
import TramiteMessageConfig from "../../components/admin/tramiteMessage";

export default class Configuration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPass: null,
            newPass: null,
            newPassRepeat: null,
            message: null,
            type: null
        }
    }

    currentPassword(value) {
        this.setState({
            currentPass: value
        });
    }
    newPassword(value) {
        this.setState({
            newPass: value
        });
    }
    confirmPassword(value) {
        this.setState({
            newPassRepeat: value
        });
    }

    attemptPassChange() {
        let {currentPass, newPass, newPassRepeat} = this.state;
        if(newPass !== newPassRepeat) {
            Toastify.error(toast, "Las contraseñas no coinciden.")
        } else if(newPass === "" || newPassRepeat === "" || currentPass === "" || newPass === null || newPassRepeat === null || currentPass === null) {
            Toastify.error(toast, "No se permiten campos vacíos.");
        } else {
            this.setState({
                currentPass: "",
                newPass: "",
                newPassRepeat: "",
            });
            let token = LocalStorageService.getUserToken();
            BackendConnectionService.changePassword(token, currentPass, newPass).then((e) => {
                let response = ResponseProcessorService.processResponse(e);
                if(response.success) {
                    Toastify.success(toast, "La contraseña se ha cambiado correctamente.");
                } else {
                    Toastify.error(toast, response.errorMsg);
                }
            }).catch((e) => {
                this.processError(e);
            });
        }
    }

    processError(error) {
        let payload = ResponseProcessorService.processResponse(error);
        if(payload.errorCode === 401) {
            LocalStorageService.logout();
            window.location.assign("/admin/inactive");
        } else if(payload.errorCode === 400) {
            Toastify.error(toast, "La contraseña actual no coincide con la del sistema.");
        } else {
            Toastify.error(toast, payload.errorMsg);
        }
    }

    render() {
        let {currentPass, newPass, newPassRepeat} = this.state;
        if(currentPass === undefined || currentPass == null) currentPass = "";
        if(newPass === undefined || newPass == null) newPass = "";
        if(newPassRepeat === undefined || newPassRepeat == null) newPassRepeat = "";

        return(
            <div id="layout-wrapper">
                <Navbar />
                <AdminMenu />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-6">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18" style={{color: "#ffff"}}>Configuración del sistema</h4>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body p-4">
                                            <div className="p-2">
                                                <h5 className="mb-5 text-center">Cambiar contraseña de administración</h5>
                                                <form>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group mb-4">
                                                                <label htmlFor="pass1">Contraseña actual</label>
                                                                <input type="password" className="form-control" id="pass1"
                                                                       placeholder="********" value={currentPass} onChange={(e) => this.currentPassword(e.target.value)} />
                                                            </div>
                                                            <div className="form-group mb-4">
                                                                <label htmlFor="pass1">Nueva contraseña</label>
                                                                <input type="password" className="form-control" id="pass1"
                                                                       placeholder="********" value={newPass} onChange={(e) => this.newPassword(e.target.value)} />
                                                            </div>
                                                            <div className="form-group mb-4">
                                                                <label htmlFor="pass1">Repita la contraseña</label>
                                                                <input type="password" className="form-control" id="pass1"
                                                                       placeholder="********" value={newPassRepeat} onChange={(e) => this.confirmPassword(e.target.value)} />
                                                            </div>

                                                            <div className="mt-4">
                                                                <button
                                                                    className="btn btn-success btn-block waves-effect waves-light"
                                                                    type="button" onClick={() => {this.attemptPassChange()}}>Cambiar contraseña
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <TramiteMessageConfig tramite="dni" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <TramiteMessageConfig tramite="passport" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        );
    }
}