import {withTranslation} from "react-i18next";
import React from "react";
import BackendConnectionService from "../../../ports/backend/backendConnectionService";
import ResponseProcessorService from "../../../ports/backend/ResponseProcessorService";
import LocalStorageService from "../../../ports/localStorage/localStorageService";
import Toastify from "../../alerts/Toastify";
import Swal from 'sweetalert2'
import {toast} from "react-toastify";
import Switch from "react-switch";

class PasaporteForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tagOrDni: "",
            disabled: false,
            isDNI: false,
        }
    }

    handleToggle = (checked) => {
        this.setState({ isDNI: checked });
    };

    updateTagOrDni(e) {
        this.setState({
           tagOrDni: e.target.value
        });
    }

    validatePass(pass) {
        if(pass === "") {
            return "Debe introducir un número de etiqueta o DNI";
        }
        return null;
    }

    triggerAction(action) {
        let {tagOrDni, isDNI} = this.state;
        let token = LocalStorageService.getUserToken();
        let validate = this.validatePass(tagOrDni);
        if(validate == null) {
            if(action === "add") {
                this.addRegistry(isDNI, tagOrDni, token);
            }
            if(action === "mark") {
                this.markRegistry(isDNI, tagOrDni, token);
            }
            if(action === "delete") {
                this.deleteRegistry(isDNI, tagOrDni, token);
            }
            this.setState({
                disabled: true
            });
        } else {
            Toastify.error(toast,validate);
        }
    }

    processError(payload) {
        if(payload.errorCode === 401) {
            LocalStorageService.logout();
            window.location.assign("/admin/inactive");
        } else {
            Toastify.error(toast, payload.errorMsg);
        }
        this.setState({
            disabled: false
        });
    }

    enableButton() {
        this.setState({
            tagOrDni: "",
            disabled: false
        });
    }

    addRegistry(isDNI, registry, token) {
        let titulo = isDNI ? "DNI" : "etiqueta";
        Swal.fire({
            title: 'Confirmación',
            text: "Está a punto de crear un pasaporte con " + titulo + " " + registry + " ¿Está seguro?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                BackendConnectionService.addPasaporte(isDNI, token, registry)
                    .then((response) => {
                        ResponseProcessorService.processResponse(response);
                        Toastify.success(toast, "Se ha creado un nuevo documento para el pasaporte");
                        this.enableButton();
                    }).catch((err) => {
                    let payload = ResponseProcessorService.processResponse(err);
                    this.processError(payload);
                })
            }
        })
    }

    markRegistry(isDNI, registry, token) {
        let titulo = isDNI ? "DNI" : "etiqueta";
        Swal.fire({
            title: 'Confirmación',
            text: "Está a punto de marcar un pasaporte con " + titulo + " " + registry + " como recogido ¿Está seguro?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Recoger pasaporte',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                BackendConnectionService.markPasaporte(token, registry)
                    .then(() => {
                        Toastify.success(toast, "Se ha actualizado el registro correctamente");
                        this.enableButton();
                    }).catch((err) => {
                    let payload = ResponseProcessorService.processResponse(err);
                    this.processError(payload);
                })
            }
        })
    }

    deleteRegistry(isDNI, registry, token) {
        let titulo = isDNI ? "DNI" : "etiqueta";
        Swal.fire({
            title: 'Confirmación',
            text: "Está a punto de eliminar un pasaporte con  " + titulo + " " + registry + " del sistema. Esto no puede revertirse ¿Está seguro?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar registro',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                BackendConnectionService.deletePasaporte(token, registry)
                    .then(() => {
                        Toastify.success(toast, "Se ha eliminado el registro correctamente");
                        this.enableButton();
                    }).catch((err) => {
                    let payload = ResponseProcessorService.processResponse(err);
                    this.processError(payload);
                })
            }
        })
    }

    render() {
        let {disabled, isDNI} = this.state;
        let text = this.props.text;
        let placeholder = isDNI ? "666777888" : "MREAC99999999999";
        let titulo = isDNI ? "DNI" : "Etiqueta";

        if(disabled) text = "Procesando...";
        
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group mb-4">
                        <label htmlFor="acceso">{titulo}</label>
                        <input type="text" className="form-control" id="acceso" onChange={(e) => this.updateTagOrDni(e)}
                            placeholder={placeholder} value={this.state.tagOrDni} />
                    </div>
                </div>

                <div className="col-md-6 ">
                    <div className="row">
                        <div className="col-md-6">
                            <label className="me-2">¿Es DNI?</label>
                        </div>
                        <div className="col-md-6">
                            <Switch
                            onChange={this.handleToggle}
                            checked={isDNI}
                            onColor="#4CAF50"
                            offColor="#ccc"
                            handleDiameter={22}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={28}
                            width={50}
                        />
                        </div>
                    </div>
                    <div className="mt-4">
                        <button
                            className="btn btn-success btn-block waves-effect waves-light"
                            type="button" onClick={() => this.triggerAction(this.props.action)} disabled={disabled}>{text}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(PasaporteForm);