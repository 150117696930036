import React from "react";
import {withTranslation} from "react-i18next";
import TramiteResponse from "./tramiteResponse";
import Toastify from "../alerts/Toastify";
import {toast} from "react-toastify";
import etiqueta from "../../assets/img/etiqueta-compressor.png";
import BackendConnectionService from "../../ports/backend/backendConnectionService";
import HCaptcha from '@hcaptcha/react-hcaptcha';

class Pasaporte extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tag: null
        }
        this.attemptQuery = this.attemptQuery.bind(this);
        this.onCaptcha = this.onCaptcha.bind(this);
    }

    changePass(pass) {
        this.setState({
            tag: pass
        });
    }

    onCaptcha(e) {
        this.setState({
            captcha: e
        });
    }

    componentDidMount() {
        toast.configure();
    }

    validatePass(pass) {
        if(pass === "") {
            return "Debe introducir un número de etiqueta";
        }
        return null;
    }

    attemptQuery() {
        // eslint-disable-next-line no-undef
        grecaptcha.reset();
        let token = this.state.captcha;
        let tag = this.state.tag;
        let context = this;
        if(token != null) {
            let validate = this.validatePass(tag);
            if(validate == null) {
                this.setState({
                    response: {
                        status: "query"
                    }
                })
                BackendConnectionService.getPasaporte(token, tag)
                    .then(response => {
                        let recogido = null;
                        if(response.data.pickUpDate !== null && response.data.pickUpDate !== undefined) {
                            recogido = response.data.pickUpDate;
                            recogido = new Date(recogido);
                            recogido = recogido.getDate() + "/" + recogido.getMonth() + "/" + recogido.getFullYear();
                        }
                        context.setState({
                            response: {
                                status: "response",
                                data: {
                                    error: false,
                                    ready: true,
                                    documento: tag,
                                    recogido: recogido
                                }
                            }
                        });
                    }).catch(function (err) {
                    if(err.response) {
                        let code = err.response.status;
                        if(code === 404) {
                            context.setState({
                                response: {
                                    status: "response",
                                    data: {
                                        error: false,
                                        ready: false,
                                        documento: tag,
                                    }
                                }
                            });
                        } else {
                            context.setState({
                                response: {
                                    status: "response",
                                    data: {
                                        error: true,
                                        ready: false,
                                        documento: tag,
                                    }
                                }
                            });
                        }
                    }
                });
            } else {
                Toastify.error(toast, validate);
            }
        } else {
            Toastify.error(toast,"Por seguridad, debe marcar No soy un robot para continuar.");
        }
    }

    render() {
        let value = this.state.tag;
        let disabled = false;
        if(this.state.response != null && this.state.response.status === "query") disabled = true;
        return (
                    <div className="col-xl-6">
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="p-2">
                                    <h5 className="mb-5 text-center">Consulta Pasaporte.</h5>
                                    <p>El número de etiqueta es el código que se sitúa debajo del código de barras de su comprobante de pago. Inicia con el prefijo MREAC.</p>
                                    <p>También se puede consultar usando el número de DNI en caso de no disponer de número de etiqueta.</p>
                                    <form>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="dni">Etiqueta o DNI</label>
                                                    <input type="text" className="form-control" id="dni"
                                                           placeholder="MREACXXXXXXX o DNI" value={value} onChange={(e) => this.changePass(e.target.value)} />
                                                </div>
                                                <div className="mt-4">
                                                    <HCaptcha 
                                                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} 
                                                        onVerify={this.onCaptcha}
                                                        languageOverride="es"
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <button
                                                        className="btn btn-success btn-block waves-effect waves-light"
                                                        type="button" onClick={() => this.attemptQuery()} disabled={disabled}>Consultar
                                                    </button>
                                                    <p/>
                                                    <button type="button"
                                                            className="btn btn-success btn-block waves-effect waves-light"
                                                            data-toggle="modal" data-target="#myModal">Ver etiqueta de muestra
                                                    </button>

                                                    <div id="myModal" className="modal fade" tabIndex="-1"
                                                         role="dialog" aria-labelledby="myModalLabel"
                                                         aria-hidden="true" style={{display:"none"}}>
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title mt-0"
                                                                        id="myModalLabel">Etiqueta de muestra</h5>
                                                                    <button type="button" className="close"
                                                                            data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">×</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <img src={etiqueta} border="0" style={{maxWidth: "70%"}} alt="etiqueta" />
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button"
                                                                            className="btn btn-secondary waves-effect"
                                                                            data-dismiss="modal">Cerrar
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <TramiteResponse response={this.state.response} tramite="passport"  />
                                </div>
                            </div>
                        </div>

                    </div>
        );
    }
}

export default withTranslation()(Pasaporte);