import React, { Component } from 'react';
import LocalStorageService from '../../ports/localStorage/localStorageService';

class Logo extends Component {
    makeImageLogo(type, instanceConfig) {
        if (type === "vertical") {
            return <img src={instanceConfig.adminLogo} height="120" alt="logo" />
        }
        return <img src={instanceConfig.headerLogo} height="60" alt="logo" />
    }
    makeTextLogo(instanceConfig) {
        const name = instanceConfig.name;
        return (<h4 className="mb-0" style={{color:"#fff", fontSize: "clamp(12px, 4vw, 18px)"}}>{name}</h4>);
    }
    render() {
        const instanceConfig = LocalStorageService.getInstanceConfiguration();
        const displayMethod = instanceConfig.displayMethod;
        let logo = "Cargando";
        if (displayMethod === "name") {
            logo = this.makeTextLogo(instanceConfig);
        } else {
            logo = this.makeImageLogo(this.props.imageType, instanceConfig);
        }
        return (
            <div>
                {logo}
            </div>
        );
    }
}

export default Logo;