import Toastify from "../../components/alerts/Toastify";
import LocalStorageService from "../localStorage/localStorageService";
import {toast} from "react-toastify";

export default class ResponseProcessorService {
    static processResponse(response) {
        if(response.response !== undefined) {
            return this.processNegativeResponse(response);
        }
        return this.processPositiveResponse(response);
    }

    static processTramiteMetadata(response) {
        let payload = this.processResponse(response);
        let fecha = new Date(payload.payload.date).toLocaleString("en-GB", { hour12: false });
        return {
            estado: payload.payload.stage,
            response: payload.payload.data,
            actualizacion: fecha
        };
    }

    static processPositiveResponse(response) {
        let payload = response.data;
        return {
            success: true,
            payload: payload
        };
    }

    static processNegativeResponse(response) {
        return {
            success: false,
            errorCode: response.response.status,
            errorMsg: response.response.data.message
        }
    }

    static processError(error) {
            let payload = ResponseProcessorService.processResponse(error);
            if(payload.errorCode === 401) {
                LocalStorageService.logout();
                window.location.assign("/admin/inactive");
            } else if(payload.errorCode === 400) {
                Toastify.error(toast, "La contraseña actual no coincide con la del sistema.");
            } else {
                Toastify.error(toast, payload.errorMsg);
            }
        }
}
