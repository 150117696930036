import React, { Component } from 'react';
import BackendConnectionService from '../../ports/backend/backendConnectionService';
import Toastify from '../alerts/Toastify';
import {toast} from "react-toastify";
import LocalStorageService from '../../ports/localStorage/localStorageService';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import ResponseProcessorService from '../../ports/backend/ResponseProcessorService';

class TramiteMessageConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successMessage: "",
            errorMessage: "",
            pickedMessage: "",
            requestInProgress: false
        }
    }

    componentDidMount() {
        this.setState({
            requestInProgress: true
        });
        BackendConnectionService.getTramiteConfiguration(this.props.tramite)
        .then((e) => {
            this.setState({
                successMessage: e.data.readyMessage,
                errorMessage: e.data.errorMessage,
                pickedMessage: e.data.pickedMessage
            });
        })
        .catch((e) => {
            console.log(e);
            ResponseProcessorService.processError(e);
            Toastify.error(toast, "Error al obtener la configuración del trámite " + this.props.tramite);
        }).finally(() => {
            this.setState({
                requestInProgress: false
            });
        });
    }

    updateMessageValue(value, type) {
        if(type === "success") {
            this.setState({
                successMessage: value
            });
        } else if (type === "picked") {
            this.setState({
                pickedMessage: value
            });
        } else {
            this.setState({
                errorMessage: value
            });
        }
    }

    changeMessage(tramite) {
        const token = LocalStorageService.getUserToken();
        let data = {
            tramite: tramite,
            readyMessage: this.state.successMessage,
            errorMessage: this.state.errorMessage,
            pickedMessage: this.state.pickedMessage
        }
        BackendConnectionService.updateTramiteConfiguration(token, tramite, data)
        .then((e) => {
            Toastify.success(toast, "Mensajes actualizados correctamente.");
        })
        .catch((e) => {
            console.log(e);
            Toastify.error(toast, "Error al actualizar los mensajes.");
        });
    }


    render() {
        let {successMessage, errorMessage, pickedMessage, requestInProgress} = this.state;
        const tramite = this.props.tramite;
        let name = "";
        
        let buttonText = "Actualizar mensajes";
        if(requestInProgress) buttonText = "Cargando...";

        if (tramite === "dni") name = "DNI";
        if (tramite === "passport") name = "Pasaporte";

        return (
            <div className="card">
                <div className="card-body p-4">
                    <div className="p-2">
                        <h5 className="mb-5 text-center">Actualizar mensajes para {name}</h5>
                        <form>
                        <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label htmlFor="successMsg">Mensaje en caso de estar recogido</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={pickedMessage}
                                            onChange={(content) => this.updateMessageValue(content, "picked")}
                                            />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Vista previa</label>
                                    <div className="alert alert-info" role="alert" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pickedMessage) }}/>  
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label htmlFor="successMsg">Mensaje en caso de estar listo</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={successMessage}
                                            onChange={(content) => this.updateMessageValue(content, "success")}
                                            />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Vista previa</label>
                                    <div className="alert alert-success" role="alert" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(successMessage) }}/>  
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                            <label htmlFor="errMsg">Mensaje en caso de no estar listo</label>
                                            <ReactQuill
                                                theme="snow"
                                                value={errorMessage}
                                                onChange={(content) => this.updateMessageValue(content, "error")}
                                                />
                                        </div>
                                    </div>
                                <div className="col-md-6">
                                    <label>Vista previa</label>
                                    <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(errorMessage) }}/>
                                        
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mt-4">
                                        <button
                                            className="btn btn-success btn-block waves-effect waves-light"
                                            type="button" onClick={() => {this.changeMessage(tramite)}} disabled={requestInProgress}>{buttonText}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default TramiteMessageConfig;