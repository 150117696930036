import React from "react";
import LocalStorageService from "../ports/localStorage/localStorageService";
import Logo from "../components/logos/logo";

export default class Mantenimiento extends React.Component  {
    render() {
        const adminEmail = LocalStorageService.getInstanceConfiguration().adminEmail;
        let emailMessage = "";
        if (adminEmail != null) {
            emailMessage = `Para cualquier consulta puede dirigirse a ${adminEmail}`;
        }
        return (
            <div className="account-pages my-5 pt-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="text-center mb-5">
                                <Logo imageType="horizontal" />
                                <h4 className="mt-4" style={{color:"#fff"}}>Mantenimiento programado</h4>
                                <p style={{color:"#fff"}}>A fin de mejorar la calidad en el servicio, el sistema de trámite listo se encuentra bajo mantenimiento programado.
                                    Volverá a estar disponible lo antes posible.</p>
                                <p style={{color:"#fff"}}>{emailMessage}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}