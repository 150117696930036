import React from "react";
import * as XLSX from "xlsx";
import FileHelper from "./FileHelper";
import LocalStorageService from "../../../ports/localStorage/localStorageService";
import BackendConnectionService from "../../../ports/backend/backendConnectionService";
import DataTable from "react-data-table-component";

class SubirPasaporte extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            file: null
        }
    }

    enableButton() {
        this.setState({
            disabled: false
        });
    }

    disableButton() {
        this.setState({
            disabled: true
        });
    }

    changeFile(e) {
        this.setState({
            file: e.target.files[0]
        });
    }

    subirArchivo() {
        this.disableButton();
        let file = this.state.file;
        let reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {raw: true});
            let cleanData = [];
            let keys = null;
            wb.SheetNames.forEach(name => {
                const ws = wb.Sheets[name];
                const data = XLSX.utils.sheet_to_json(ws, {});
                if(keys == null) keys = Object.keys(data[0]);
                data.forEach(row => {
                    cleanData.push(row);
                })
            });
            if(keys != null) {
                this.setState({
                    columnas: keys,
                    data: cleanData
                });
            }
        };
        reader.readAsArrayBuffer(file);
        this.enableButton();
    }

    generateOpts() {
        return FileHelper.generateOpts(this.state.columnas);
    }

    submitMapping() {
        this.setState({
            mapped: true
        });
    }

    mapId(e) {
        this.setState({
            idMap: e.target.value
        });
    }
    mapDni(e) {
        this.setState({
            dniMap: e.target.value
        });
    }

    extractNumbers(str) {
        return str.replace(/\D/g, '');
    }

    prepareData() {
        let {data, idMap, columnas, dniMap} = this.state;
        let cleanData = [];
        data.forEach(x => {
            let push = {
                tagId: x[columnas[idMap]],
                dni: this.extractNumbers(x[columnas[dniMap]])
            };
            cleanData.push(push);
        });
        return cleanData;
    }

    submitPass(data) {
        this.setState({
            disabled: true
        });
        let token = LocalStorageService.getUserToken();
        BackendConnectionService.uploadPasaportesBatch(token, data).then((e) => {
            this.setState({
                disabled: false,
                file: null,
                mapped: null,
                data: null,
                dniMap: null,
                registroMap: null,
                recogidoMap: null,
                columnas: null
            });
            window.location.reload();
        });
    }

    render() {
        let disabled = this.state.disabled;
        let file = this.state.file;
        let text = "Subir Pasaportes"
        if(disabled) text = "Procesando...";
        if(file == null) {
            text = "Seleccione un archivo para continuar";
            file = "Click para seleccionar";
            disabled = true;
        }
        else file = file.name;
        if(this.state.data != null && this.state.mapped == null) {
            return (
                <div className="col-lg-12">
                    <p>Para cada opción, seleccione la columna correspondiente.</p>
                    <div className="col-md-12 mb-3">
                        <label>Número de etiqueta</label>
                        <select className="custom-select" onChange={(e) => this.mapId(e)} required="">
                            {this.generateOpts()}
                        </select>
                    </div>
                    <div className="col-md-12 mb-3">
                        <label>DNI</label>
                        <select className="custom-select" onChange={(e) => this.mapDni(e)} required="">
                            {this.generateOpts()}
                        </select>
                    </div>
                    <div className="mt-4">
                        <button
                            className="btn btn-success btn-block waves-effect waves-light"
                            type="button" onClick={() => this.submitMapping()}>Continuar
                        </button>
                    </div>
                </div>
            );
        } else if(this.state.mapped != null) {
            let {idMap, dniMap} = this.state;
            let keys = this.state.columnas;
            let cols = [{
                name: "Etiqueta",
                selector: row => {
                    let selector1 = keys[idMap];
                    return row[selector1];
                }
            }, {
                name: "DNI",
                selector: row => {
                    let selector = keys[dniMap];
                    return row[selector];
                }
            }];
            let dataToBePushed = this.prepareData();
            return (
                <div className="col-lg-12">
                    <p>Previsualización de datos. Verifique que la información mostrada corresponde con lo que debe subirse al sistema.</p>
                    <DataTable
                        pagination
                        highlightOnHover
                        columns={cols}
                        data={this.state.data}
                    />
                    <div className="mt-4">
                        <button
                            className="btn btn-success btn-block waves-effect waves-light"
                            type="button" onClick={() => this.submitPass(dataToBePushed)} disabled={disabled}>{text}
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-lg-12">
                    <div className="form-group mb-4">
                        <label>Archivo</label>
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" accept=".csv,.xlsx,.xls" id="validationCustomFile" onChange={(e) => this.changeFile(e)} required="" />
                            <label className="custom-file-label" htmlFor="validationCustomFile">{file}</label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button
                            className="btn btn-success btn-block waves-effect waves-light"
                            type="button" onClick={() => this.subirArchivo()} disabled={disabled}>{text}
                        </button>
                    </div>
                </div>
            );
        }
    }


}

export default SubirPasaporte;