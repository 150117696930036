import React, { Component } from 'react';
import LocalStorageService from '../ports/localStorage/localStorageService';

class Name extends Component {
    render() {
        const year = new Date().getFullYear();
        let name = LocalStorageService.getInstanceConfiguration().name;
        if (name == null) {
            name = "Consulado General del Perú";
        }
        return (
            <div className="col-sm-6">
                {year} © {name}.
            </div>
        );
    }
}

export default Name;